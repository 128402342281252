import { useState } from "react";
import useBiconomyContext from "./useBiconomyContext";

const useMetaTransaction = ({ input, transactionParams }) => {
  const { nonMetaContract } = useBiconomyContext();
  const [isMetatransactionProcessing, setIsMetatransactionProcessing] = useState(false);
  const [mintClicked, setMintClicked] = useState(false);
  const [mintingStart, setMintingStart] = useState(true);
  const [congratsState, setCongratsState] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [error, setError] = useState();


  const isWhitelisted = async () => {
    const result = await nonMetaContract.methods.whiteList(transactionParams.from).call();
    return result;
  }

  const getTotalSupply = async () => {
    const nft1 = await nonMetaContract.methods.totalSupply(0).call();
    const nft2= await nonMetaContract.methods.totalSupply(1).call();
    return parseInt(nft1) + parseInt(nft2);
  }

  const onSubmitMetaTransaction = async ({ onConfirmation, onError }) => {
    try {
      setMintClicked(true);
      let tx = nonMetaContract.methods.publicMint(input).send(transactionParams);
      tx.on("transactionHash", function (hash) {
        setIsMetatransactionProcessing(true);
        setMintingStart(false);
        setTransactionHash(hash);
        console.log(hash);
      })
        .once("confirmation", function (transactionHash) {
          setIsMetatransactionProcessing(false);
          setCongratsState(true)
          setMintClicked(false)
          onConfirmation(transactionHash);
        })
        .on("error", function (e) {
          setError(e);
          setMintClicked(false);
          setIsMetatransactionProcessing(false);
          onError();
        });
    } catch (e) {
      console.log("error", e)
      setError(e);
      onError();
    }
  };

  return { error, isMetatransactionProcessing, onSubmitMetaTransaction, mintingStart, congratsState, transactionHash, mintClicked, isWhitelisted, getTotalSupply };
};

export default useMetaTransaction;
