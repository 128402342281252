import { useMoralis } from "react-moralis";
function NotWhitelist(props) {
  const { logout } = useMoralis();
    return (
      <>
          <h2>YOU ARE NOT WHITELISTED!</h2>
          <div>Oops, something’s not right. Looks like you are not whitelisted to mint the NFT right now.</div>
          <div className='actions'>
            <button 
              className="transparent-button" onClick={logout}>
              Start Over
            </button>
          </div>
      </>
    )
  }
  
  export default NotWhitelist