import { getOpenSeaUrl } from "helpers/networks";
import { useMemo } from "react";
import { useChain } from "react-moralis";
import simpleStorage from "list/contractAddresses.json";

function Congrats() {

  const { chainId } = useChain();
  const contractAddress = useMemo(() => simpleStorage[chainId], [chainId]);

  const viewOnOpenSea = () => {
    const url = getOpenSeaUrl(contractAddress)
    window.open(url, "_blank");
  }

  return (
    <>
        <h2>CONGRATULATIONS!</h2>
        <div>All set! Your transaction has been confirmed and your NFT has been sent. It will appear in you wallet shortly.</div>
        <div className='description'>Click the button below to check it out on OpenSea.</div>
        <div className='actions'>
        <button onClick={viewOnOpenSea} className="filled-button">View My NFT On OpenSea</button>
        </div>
    </>
  )
}

export default Congrats