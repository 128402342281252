import React from "react";
import { useMoralis } from "react-moralis";
import { getActive } from "helpers/networks";
import styled from "styled-components";
import "./StartMinting.css";

function StartMinting(props) {
  const { isAuthenticated, authenticate, logout } = useMoralis();
  
  return (
    <>
      {
        !isAuthenticated
          ?<h2>
            <span>NFT</span> FOR COMMUNAL
            <div style={{ color: "#00DF00", fontWeight: "bold", marginTop: 0 }}>WEALTH <span style={{ fontStyle: "italic" }}>BUILDING</span></div>
          </h2>
          : <h2>
            MINT YOUR NFT
          </h2>
      }

      
      <div className='description'>
        {
          isAuthenticated
           ? <>
              Select quantity of NFTs to mint.
            </>
           : <div>
                <div>
                The AITG All In Access Pass NFT is our first step in bridging the gap between modern day decentralized technology and traditional money management.
                </div>
                <div style={{ marginTop: 12 }}>
                  Connect Wallet to mint your NFT
                </div>
            </div>
        }
      </div>
      { isAuthenticated &&
          <Amount>
            <IncDecbutton
              onClick={()=>props.updateAmount(props.amount-1)}>
                  -
            </IncDecbutton>
            <Num>
                {props.amount}
            </Num>
            <IncDecbutton
              onClick={()=>props.updateAmount(props.amount+1)}>
                  +
            </IncDecbutton>
            <Price>
              {parseFloat(props.price * props.amount).toFixed(2)} ETH
            </Price>
          </Amount>
      }
      {isAuthenticated &&
        <div className="description">
          155 max reserved NFTs
          <SubDescription>
            <span> {props.supply} minted / 155</span>
            <span>{props.price} ETH per NFT</span>
          </SubDescription>
          <SubDescription>
            <span> Max 5 NFTs/wallet</span>
          </SubDescription>
        </div>
      }
      <div className="actions">
        {isAuthenticated ? (
          <>
            <button 
              disabled={props.mintClicked}
              className="filled-button" onClick={props.mint}>
                MINT NOW
            </button>
            <button 
              disabled={props.mintClicked}
              className="transparent-button" onClick={logout}>
              Start Over
            </button>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "20px"
            }}
          >
            <button
              className="filled-button"
              onClick={() =>
                authenticate({ signingMessage: "Hello future AITG NFT Holder!" })
              }
            >
              Connect Metamask
            </button>
            <button className="transparent-button" onClick={()=>window.open("https://nft.allintradinggroup.com/", "_blank")}>Learn More</button>
          </div>
        )}
      </div>
    </>
  );
}

export default StartMinting;


const Amount = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const IncDecbutton = styled.button`
  outline: none;
  border: 1px solid white;
  background: transparent;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :hover{
    color: #00DF00;
    border-color: #00DF00;
  }
`

const Num = styled.div`
  font-size: 18px;
`

const Price = styled.div`
  font-size: 18px;
`

const SubDescription = styled.div`
    color: #999;
    display: flex;
    gap: 20px;
    margin-top: 1.2rem;
`