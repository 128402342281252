const REACT_APP_ACTIVE_CHAIN = process.env.REACT_APP_ACTIVE_CHAIN;

export const networkConfigs = {
  "0x1": {
    chainId: 1,
    chainInfoForSwitching: {
      "chainName":"Ethereum Mainnet",
      "rpcUrls":[
        "https://eth-mainnet.alchemyapi.io/v2/vyV5Lpuih1aNWfGGTmpdMYMQxIvSgoIL"
      ],
      "nativeCurrency":{
        "name":"Ether",
        "symbol":"ETH",
        "decimals":18
      },
      "chainId":"0x4",
      "blockExplorerUrls":["https://etherscan.io/"]
    },
    chainName: "Ethereum Mainnet",
    currencyName: "Ether",
    currencySymbol: "ETH",
    rpcUrl: `https://eth-mainnet.alchemyapi.io/v2/vyV5Lpuih1aNWfGGTmpdMYMQxIvSgoIL`,
    blockExplorerUrl: "https://etherscan.io/tx/",
    openSeaUrl: `${process.env.REACT_APP_OPENSEA_URL}`
  },
  "0x4": {
    chainId: 4,
    chainInfoForSwitching: {
      "chainName":"Rinkeby",
      "rpcUrls":[
        "https://eth-rinkeby.alchemyapi.io/v2/VBNJxg3_jPPPQ-WugcTw_-hLiIzU3OW6"
      ],
      "nativeCurrency":{
        "name":"Rinkeby Ether",
        "symbol":"RIN",
        "decimals":18
      },
      "chainId":"0x4",
      "blockExplorerUrls":["https://rinkeby.etherscan.io/"]
    },
    chainName: "Rinkeby",
    currencyName: "Rinkeby Ether",
    currencySymbol: "RIN",
    rpcUrl: `https://eth-rinkeby.alchemyapi.io/v2/VBNJxg3_jPPPQ-WugcTw_-hLiIzU3OW6`,
    blockExplorerUrl: "https://rinkeby.etherscan.io/tx/",
    openSeaUrl: `${process.env.REACT_APP_OPENSEA_URL}`
  },
  "0x89": {
    chainId: 137,
    chainInfoForSwitching: {
      "chainName":"Polygon Mainnet",
      "rpcUrls":[
        "https://rpc-mainnet.maticvigil.com"
      ],
      "nativeCurrency":{
        "name":"MATIC",
        "symbol":"MATIC",
        "decimals":18
      },
      "chainId":"0x89",
      "blockExplorerUrls":["https://polygonscan.com/"]
    },
    chainName: "Polygon Mainnet",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/eZq0fYvz4BqhYxebPMTTfRrjtqORmi1J`,
    blockExplorerUrl: "https://polygonscan.com/tx/",
    openSeaUrl: `${process.env.REACT_APP_OPENSEA_URL}`
  },
  "0x13881": {
    chainId: 80001,
    chainInfoForSwitching: {
        "chainName":"Mumbai",
        "rpcUrls":[
          "https://rpc-mumbai.maticvigil.com"
        ],
        "nativeCurrency":{
          "name":"MATIC",
          "symbol":"MATIC",
          "decimals":18
        },
        "chainId":"0x13881",
        "blockExplorerUrls":["https://mumbai.polygonscan.com"]
    },
    chainName: "Mumbai",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: `https://speedy-nodes-nyc.moralis.io/${process.env.REACT_APP_MORALIS_SPEEDY_NODES_KEY}/polygon/mumbai`,
    blockExplorerUrl: "https://mumbai.polygonscan.com/tx/",
    openSeaUrl: `${process.env.REACT_APP_OPENSEA_URL}`
  },
};



export const getNativeByChain = (chain) =>
  networkConfigs[chain]?.currencySymbol || "NATIVE";

export const getChainById = (chain) => networkConfigs[chain]?.chainId || null;

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain) =>
  networkConfigs[chain]?.wrapped || null;

export const getActive = () => {
  return networkConfigs[REACT_APP_ACTIVE_CHAIN];
}

export const getOpenSeaUrl = (contract) => {
  const network = getActive();
  return network.openSeaUrl;
}

export const getEtherscanUrl = () => {
  const network = getActive();
  return network.blockExplorerUrl;
}
