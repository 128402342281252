import styled from "styled-components";
import twitter from "../../assets/twitter.svg";
import discord from "../../assets/discord.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";

const Footer = () => {
  return (
    <Container>
        <Main>
            <Link href="https://twitter.com/aitgnft">
                <Image src={twitter} />
            </Link>
            <Link href="https://discord.com/invite/hR649Dqbsv">
                <Image style={{ width: 40 }}src={discord} />
            </Link>
        </Main>
    </Container>
  );
};

export default Footer;

const Link = styled.a`
`

const Main = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  @media (max-width: 950px) {
    max-width: 450px;
    gap: 0;
    padding: 0 24px;
    flex: 1;
    justify-content: space-between;
  }
`;

const Image = styled.img`
  cursor: pointer;
  width: 50px;
  transition: all 0.2s ease-out;
  &:hover {
    -webkit-filter: drop-shadow(0 0 4px #9266EB);
    filter: drop-shadow(0 0 4px #9266EB);
  }
`;

const Container = styled.div`
  z-index: 10000;
  height: 50px;
  position: absolute;
  bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
`;
