import styled from "styled-components";

import "./App.css";
import bg from "./assets/bg.png";
import Footer from "components/Footer";
import Minting from "components/Minting";
import Logo from "assets/logo.png";

const App = () => {
  return (
    <Container>
      <LogoImage src={Logo} width="auto" height="30px" />
      {/* <Footer /> */}
      <Minting />
    </Container>
  );
};

export default App;

const LogoImage = styled.img`
  z-index: 100;
  margin-bottom: 32px;

  @media only screen and (max-width: 960px) {
    position: absolute;
    margin-bottom: 0;
    z-index: 10000;
    top: 20px;
  }
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  min-height: 750px;
  display: flex;
  position: relative;
  background: #261A44;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

