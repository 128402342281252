/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMoralis, useChain } from "react-moralis";
import useBiconomyContext from "hooks/useBiconomyContext";
import useMetaTransaction from "hooks/useMetaTransaction";
import StartMinting from "components/StartMinting";
import Congrats from "components/Congrats";
import NotWhitelist from "components/NotWhitelisted";
import { getEtherscanUrl } from "helpers/networks";
import IncorrectChain from "components/IncorrectChain";
import nftVideo from "../../assets/nft.mp4";
import styled from "styled-components";
import ball from "../../assets/ball1.png";
import ball2 from "../../assets/ball2.png";
import InProgress from "components/InProgress";
import {ethers} from "ethers"
import "./Minting.css"

const REACT_APP_ACTIVE_CHAIN = process.env.REACT_APP_ACTIVE_CHAIN;

export default function Minting() {
    const [supply, setSupply] = useState();
    const [whiteListed, setWhiteListed ] = useState(true); 
    const { nonMetaContract } = useBiconomyContext();
    const { isAuthenticated } = useMoralis();
    const [amount, setAmount ] = useState(1);
    const [price, setPrice] = useState(0.2)
    const [maxPerTx, setMarPerTx] = useState(5);
    const { account } = useMoralis();
    const { chainId } = useChain();

    const updateAmount = (num) => {
      if(num > 0 && num <= maxPerTx){
        setAmount(num)
      }
    }

  /**
   * @description For executing meta transaction
   *
   * @param {String} input - New storage data
   * @param {Address} transactionParams.from - address that will sign the metatransaction
   * @param {String} transactionParams.signatureType - either EIP712_SIGN or PERSONAL_SIGN
   */
  const { isMetatransactionProcessing, onSubmitMetaTransaction, mintingStart, congratsState, transactionHash, mintClicked, isWhitelisted, getTotalSupply } =
    useMetaTransaction({
      input: amount,
      transactionParams: {
        from: account,
        value: ethers.utils.parseEther(parseFloat(price * amount).toFixed(2).toString()).toString()
      },
    });

  /**
   * @description Execute `getStorage` call from smart contract
   *
   * @param {Function} onSuccess - success callback function
   * @param {Function} onError - error callback function
   * @param {Function} onComplete -complete callback function
   */
  const onGetStorage = ({ onSuccess, onError, onComplete }) => {
    onSuccess();
  };

  const isCorrectChain  = () => {
    return chainId == REACT_APP_ACTIVE_CHAIN;
  }

  /**
   * @description if `isEdit` is true, execute meta transaction,
   * otherwise set `isEdit` to true
   *
   * @param {*} e
   */
  const onSubmit = async (e) => {
    await e.preventDefault();
    onSubmitMetaTransaction({
      onConfirmation: () => {
          console.log("confirmed");
        onGetStorage({
          onSuccess: () => {
          },
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  

  const checkEtherscan = () => {
    let url = getEtherscanUrl();
    if(transactionHash){
        window.open(url + transactionHash, "_blank");
    }
  }

    const getMintingState =  () =>  {

        // if(!window.ethereum || !window.ethereum.isMetaMask){
        //   return <Web3NotEnabled />
        // } else 

      //   else if(!whiteListed && isAuthenticated){
      //     return <NotWhitelist />
      // }
        if(isAuthenticated && !isCorrectChain() && chainId){
          return <IncorrectChain />
        } else if(congratsState){
            return <Congrats checkEtherscan={checkEtherscan} />;
        } else if (isMetatransactionProcessing) {
            return <InProgress checkEtherscan={checkEtherscan} />;
        } else if (mintingStart) {
            return <StartMinting 
                mint={onSubmit}
                mintClicked={mintClicked}
                supply={supply}
                amount={amount}
                price={price}
                updateAmount={updateAmount}
            />;
        }
    }

    useEffect(async () => {

      if (nonMetaContract  && isCorrectChain() && chainId) {
        const totalSupply = await getTotalSupply();
        setSupply(totalSupply)
        // const isOnWhiteList = await isWhitelisted();
        // setWhiteListed(isOnWhiteList);
      }
    }, [nonMetaContract, chainId]);

  return (
    <div
      className="main"
    >
        <Ball src={ball} />
        <Ball2 src={ball2} />
        <div className='main-right-minting'>
            {getMintingState()}
        </div>
        <div
          style={{
            backgroundSize: "contain",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          className='main-left-nft'>
          <div className="video-wrapper">
            <video className='nft-video' src={nftVideo} width="300" height="300" playsInline={true} muted={true} autoPlay={true} loop={true}/>
          </div>
        </div>
    </div>
  );
}

const Ball = styled.img`
  position: absolute;
  right: 100px;
  top: -55px; 
  z-index: 1000;
  width: 100px;
`

const Ball2 = styled.img`
  position: absolute;
  left: 100px;
  bottom: -55px; 
  z-index: 1000;
  width: 100px;
`