/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState, useMemo } from "react";
import { useChain, useMoralis } from "react-moralis";
import Web3 from "web3";
import rensHoodiesContract from "contracts/Contract.json";
import simpleStorage from "list/contractAddresses.json";

export const BiconomyContext = createContext({});

const BiconomyContextProvider = (props) => {
  const { children } = props;
  let web3;
  const {
    isWeb3Enabled,
    isAuthenticated,
    isWeb3EnableLoading,
    enableWeb3,
    Moralis,
  } = useMoralis();
  const { chainId } = useChain();
  const [nonMetaContract, setNonMetaContract] = useState(null);
  const { abi } = rensHoodiesContract;
  const contractAddress = useMemo(() => simpleStorage[chainId], [chainId]);
  console.log("Contract", contractAddress); 

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading && chainId) {
      enableWeb3();
    }
  }, [isAuthenticated, isWeb3Enabled, chainId]);

  useEffect(() => {
    const initNonMetaContract = async () => {
      const walletWeb3 = await Moralis.enableWeb3();
      let nonMetaWeb3 = new Web3(walletWeb3.currentProvider);
      let contract = new nonMetaWeb3.eth.Contract(abi, contractAddress);
      setNonMetaContract(contract);
    }

    if (isAuthenticated && isWeb3Enabled && chainId) {
      initNonMetaContract();
    }
  }, [
    isAuthenticated,
    isWeb3Enabled,
    chainId,
    web3,
    abi,
    contractAddress,
    Moralis,
  ]);

  return (
    <BiconomyContext.Provider
      value={{ nonMetaContract }}
    >
      {children}
    </BiconomyContext.Provider>
  );
};

export default BiconomyContextProvider;
